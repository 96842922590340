import { Injectable } from '@angular/core';
import {MetersKindLink} from '../interfaces/meters-kind-link.interface';

@Injectable({
  providedIn: 'root'
})
export class MetersKindsTabLinksService {
  // Links to tabs with particular meters type data to be used
  // in particular module routing
  private metersKindsTabLinks: MetersKindLink[] = [
    {
      label: 'Elektřina',
      icon: 'flash_on',
      link: 'elektrina',
      index: 0
    }, {
      label: 'Plyn',
      icon: 'local_fire_department',
      link: 'plyn',
      index: 1
    },
    {
      label: 'Voda',
      icon: 'opacity',
      link: 'voda',
      index: 2
    },
    {
      label: 'Zúčtování tepla',
      icon: 'fireplace',
      link: 'teplo',
      index: 3
    }
  ];
  constructor() { }

  /**
   * Returns links to meter type tabs
   */
  public getMetersKinsTabLinks(): MetersKindLink[] {
    return this.metersKindsTabLinks;
  }
}
